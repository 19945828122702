var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('transition',{attrs:{"name":"slide"}},[(_vm.isVisible)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"p-3"},[_c('div',{staticClass:"title-text"},[_vm._v(" Você gostaria de estender a proteção para o seu ciclocomputador? ")]),_c('div',{staticClass:"title-description"},[_vm._v(" (GPS, medidor de potência, acessórios Garmin..) ")])])],1),_c('b-row',[_c('b-col',{staticClass:"p-3"},[_c('div',{staticClass:"box-info"},[_c('div',[_c('svg',{staticClass:"info-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14px","height":"14px","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"9"}}),_c('line',{attrs:{"x1":"12","y1":"8","x2":"12","y2":"9"}}),_c('line',{attrs:{"x1":"12","y1":"11","x2":"12","y2":"17"}})])]),_vm._v(" Em caso de sinistro, será obrigatório a apresentação da nota fiscal do aparelho com data anterior a contratação do seguro ")])])],1),_c('div',{staticClass:"text-center mt-3 op"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{class:{
              'btn-checkd mr-2': _vm.selected === false,
              'btn-noCheck': _vm.selected !== false,
            },attrs:{"type":"button"},on:{"click":function($event){return _vm.selectOption(false)}}},[_vm._v(" Não "),(_vm.selected === false)?_c('svg',{staticClass:"ml-1 mb-1",attrs:{"width":"18","height":"18","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_3749_4704)"}},[_c('path',{attrs:{"d":"M22.8186 4.43101L8.99963 18.249C8.90672 18.3423 8.79631 18.4163 8.67473 18.4668C8.55315 18.5173 8.42279 18.5433 8.29113 18.5433C8.15948 18.5433 8.02912 18.5173 7.90754 18.4668C7.78596 18.4163 7.67554 18.3423 7.58263 18.249L2.23863 12.9C2.14572 12.8067 2.03531 12.7327 1.91373 12.6822C1.79214 12.6317 1.66179 12.6057 1.53013 12.6057C1.39848 12.6057 1.26812 12.6317 1.14654 12.6822C1.02496 12.7327 0.914542 12.8067 0.821633 12.9C0.728356 12.9929 0.654344 13.1033 0.603842 13.2249C0.55334 13.3465 0.527344 13.4769 0.527344 13.6085C0.527344 13.7402 0.55334 13.8705 0.603842 13.9921C0.654344 14.1137 0.728356 14.2241 0.821633 14.317L6.16763 19.662C6.73158 20.2249 7.49583 20.5411 8.29263 20.5411C9.08944 20.5411 9.85369 20.2249 10.4176 19.662L24.2356 5.84701C24.3288 5.75412 24.4026 5.64377 24.4531 5.52228C24.5035 5.40079 24.5294 5.27054 24.5294 5.13901C24.5294 5.00747 24.5035 4.87723 24.4531 4.75574C24.4026 4.63425 24.3288 4.5239 24.2356 4.43101C24.1427 4.33773 24.0323 4.26372 23.9107 4.21322C23.7891 4.16272 23.6588 4.13672 23.5271 4.13672C23.3955 4.13672 23.2651 4.16272 23.1435 4.21322C23.022 4.26372 22.9115 4.33773 22.8186 4.43101Z","fill":"#ffffff"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_3749_4704"}},[_c('rect',{attrs:{"width":"24","height":"24","fill":"white","transform":"translate(0.5)"}})])])]):_vm._e()]),_c('button',{class:{
              'btn-checkd ml-2': _vm.selected === true,
              'btn-noCheck': _vm.selected !== true,
            },attrs:{"type":"button"},on:{"click":function($event){return _vm.selectOption(true)}}},[_vm._v(" Sim "),(_vm.selected === true)?_c('svg',{staticClass:"ml-1 mb-1",attrs:{"width":"18","height":"18","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_3749_4704)"}},[_c('path',{attrs:{"d":"M22.8186 4.43101L8.99963 18.249C8.90672 18.3423 8.79631 18.4163 8.67473 18.4668C8.55315 18.5173 8.42279 18.5433 8.29113 18.5433C8.15948 18.5433 8.02912 18.5173 7.90754 18.4668C7.78596 18.4163 7.67554 18.3423 7.58263 18.249L2.23863 12.9C2.14572 12.8067 2.03531 12.7327 1.91373 12.6822C1.79214 12.6317 1.66179 12.6057 1.53013 12.6057C1.39848 12.6057 1.26812 12.6317 1.14654 12.6822C1.02496 12.7327 0.914542 12.8067 0.821633 12.9C0.728356 12.9929 0.654344 13.1033 0.603842 13.2249C0.55334 13.3465 0.527344 13.4769 0.527344 13.6085C0.527344 13.7402 0.55334 13.8705 0.603842 13.9921C0.654344 14.1137 0.728356 14.2241 0.821633 14.317L6.16763 19.662C6.73158 20.2249 7.49583 20.5411 8.29263 20.5411C9.08944 20.5411 9.85369 20.2249 10.4176 19.662L24.2356 5.84701C24.3288 5.75412 24.4026 5.64377 24.4531 5.52228C24.5035 5.40079 24.5294 5.27054 24.5294 5.13901C24.5294 5.00747 24.5035 4.87723 24.4531 4.75574C24.4026 4.63425 24.3288 4.5239 24.2356 4.43101C24.1427 4.33773 24.0323 4.26372 23.9107 4.21322C23.7891 4.16272 23.6588 4.13672 23.5271 4.13672C23.3955 4.13672 23.2651 4.16272 23.1435 4.21322C23.022 4.26372 22.9115 4.33773 22.8186 4.43101Z","fill":"#ffffff"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_3749_4704"}},[_c('rect',{attrs:{"width":"24","height":"24","fill":"white","transform":"translate(0.5)"}})])])]):_vm._e()])])])],1):_vm._e()]),_c('button',{staticClass:"footer-button-back",attrs:{"id":"footerButtonBack"},on:{"click":_vm.previousStep}},[_vm._v(" Voltar ")]),_c('button',{staticClass:"footer-button",attrs:{"id":"footerButton","disabled":_vm.loading},on:{"click":_vm.handleClick}},[_vm._v("Avançar")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }